import React from 'react';
import {ConfigProvider, DatePicker, Layout, Menu, MenuProps, theme} from "antd";
import '../App.css';
import locale from 'antd/es/locale/uk_UA';
import moment from 'moment';
import {Content, Header, Footer } from "antd/es/layout/layout";
import {
    AppstoreOutlined,
    BarChartOutlined,
    CloudOutlined,
    ShopOutlined,
    TeamOutlined,
    UploadOutlined,
    UserOutlined,
    VideoCameraOutlined,
} from '@ant-design/icons';
import Sider from "antd/es/layout/Sider";


import Calendar from "./calendar";
import {Outlet} from "react-router-dom";
import SideBarMenu from "./sidebar";

moment.locale('uk', {
    week: {
        dow: 1 /// Date offset
    }
});

moment.locale('en', {
    week: {
        dow: 1,
    },
});

function Base() {
    const items: MenuProps['items'] = [
        UserOutlined,
        VideoCameraOutlined,
        UploadOutlined,
        BarChartOutlined,
        CloudOutlined,
        AppstoreOutlined,
        TeamOutlined,
        ShopOutlined,
    ].map((icon, index) => ({
        key: String(index + 1),
        icon: React.createElement(icon),
        label: `nav ${index + 1}`,

    }));

    const siderStyle: React.CSSProperties = {
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        insetInlineStart: 0,
        top: 0,
        bottom: 0,
        scrollbarWidth: 'thin',
        scrollbarColor: 'unset',
    };

    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    moment.locale('uk', {
        week: {
            dow: 1 /// Date offset
        }
    });
    console.log(locale)

    return (
        <Layout>
            <ConfigProvider locale={locale}>
         <SideBarMenu/>
             <Layout style={{ marginInlineStart: 200 }}>
                <Header style={{ padding: 0, background: colorBgContainer }} />
                <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
                    <Outlet/>
                </Content>
                <Footer style={{ textAlign: 'center' }}>
                Aid46 ©{new Date().getFullYear()}
             </Footer>
            </Layout>
            </ConfigProvider>
        </Layout>
);
}

export default Base;


/*
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
 */