import React from "react";
import {Badge, Calendar, ConfigProvider, Layout} from "antd";
import {Content} from "antd/es/layout/layout";
import locale from "antd/es/locale/uk_UA";
import {Link} from "react-router-dom";
import moment from "moment";

export interface calendarData{
    type :string
    content :string
    id: string
}


function  CalendarPage () {


  function  getListData(value:any ):  calendarData[]{
        let result :calendarData[]  = [];
        switch (value.day()){
            case 0:
                result = [{ type: 'success', content: 'BLS ' , id: 'bls' } ]
                break;
            case 6:
                result = [{ type: 'success', content: 'Stop the bleed', id :'stb' } ]
                break;
        }
        return result
    }

    function dateCellRender(value: any) {

        const listData = getListData(value );
        return (
            <ul className="events">
                {listData.map(item => (
                    <li key={item.content}>
                        <Link to = {`/course/${item.id}`}><i className="icon icon-widgets"/>
                            {item.content}</Link>
                    </li>
                ))}
            </ul>
        );
    }


     return (
         <Layout>
             <ConfigProvider locale={locale}>
                 <Content>
                     <Calendar
                         cellRender={dateCellRender}
                     />
                 </Content>
             </ConfigProvider>
         </Layout>
     )


}
export default CalendarPage;