import {useParams} from "react-router-dom";


export default function Course() {
    let { id } = useParams<"id">();

    return (
        <div>
            <h2>
                Приєднайтесь до курсу {id}
            </h2>
        </div>

    )
}