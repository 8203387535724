import Sider from "antd/es/layout/Sider";
import {Menu} from "antd";
import React, {useState} from "react";
import {Link} from "react-router-dom";


const siderStyle: React.CSSProperties = {
    overflow: 'auto',
    height: '100vh',
    position: 'fixed',
    insetInlineStart: 0,
    top: 0,
    bottom: 0,
    scrollbarWidth: 'thin',
    scrollbarColor: 'unset',
};


class SideBarMenu extends React.Component<any, any> {

    state  = {
        current: 'home',
    };

    handleClick = (e:any) => {
        console.log('click ', e);
        this.setState({
            current: e.key,
        });
    };

render()
    {
        return (
            <Sider style={siderStyle}>
                <div className="demo-logo-vertical"/>
                <Menu
                    selectedKeys={[this.state.current]}
                    onClick={this.handleClick}
                    theme="dark" mode="inline">
                    <Menu.Item key="home">
                    <span>
                        <Link to="/"><i className="icon icon-widgets"/>
                            Главная</Link>
                       </span>
                    </Menu.Item>
                    <Menu.Item key="calendar">
                    <span>
                    <Link to="/calendar"><i className="icon icon-widgets"/>
                            Календар</Link>
                    </span>
                    </Menu.Item>
                </Menu>
            </Sider>
        );
    }
}

export default SideBarMenu;