import {createBrowserRouter, useRouteError} from "react-router-dom";
import {deferredLoader, DeferredPage, Layout, Todo, todoLoader} from "../App";
import Base from "./base";
import * as React from "react";
import Calendar from "./calendar";
import Course from "./course";


export function Router() {
    return createBrowserRouter([
        {
            path: "/",
            Component: Base,
            children: [
                {
                    index: true,
                    Component: Layout,
                },
                {
                    path: "calendar",
                    Component: Calendar,
                    ErrorBoundary: TodosBoundary,
                },
                {
                    path : "course/:id",
                    Component: Course,
                }

            ],
        },
    ]);
}

export function TodosBoundary() {
    let error = useRouteError() as Error;
    return (
        <>
            <h2>Error 💥</h2>
            <p>{error.message}</p>
        </>
    );
}